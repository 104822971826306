@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roobert";
  font-display: swap;
  src: url("./fonts/Roobert-Regular.woff2") format("woff2"),
    url("./fonts/Roobert-Regular.woff") format("woff");
  font-weight: regular;
  font-style: normal;
}

body {
  font-family: "Lexend", sans-serif;
  font-weight: 300;
}

.transition-bg {
  transition: background-color 0.5s ease-in-out;
}

a {
  text-decoration: none;
  color: #be185d; /* pink-700 */
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  padding: 0.25rem;
}

a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.25rem;
}
