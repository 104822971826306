@import "https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Roobert, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.left-6 {
  left: 1.5rem;
}

.right-6 {
  right: 1.5rem;
}

.top-6 {
  top: 1.5rem;
}

.z-10 {
  z-index: 10;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-6 {
  gap: 1.5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.break-all {
  word-break: break-all;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-none {
  border-style: none;
}

.border-stone-300 {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

.border-stone-400 {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

.bg-\[\#156060\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 96 96 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.p-6 {
  padding: 1.5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.text-center {
  text-align: center;
}

.font-lexend {
  font-family: Lexend, sans-serif;
}

.font-sans {
  font-family: Roobert, sans-serif;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.leading-tight {
  line-height: 1.25;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-stone-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Roobert;
  font-display: swap;
  src: url("Roobert-Regular.c85b7ba3.woff2") format("woff2"), url("Roobert-Regular.3df39764.woff") format("woff");
  font-weight: regular;
  font-style: normal;
}

body {
  font-family: Lexend, sans-serif;
  font-weight: 300;
}

.transition-bg {
  transition: background-color .5s ease-in-out;
}

a {
  color: #be185d;
  padding: .25rem;
  text-decoration: none;
  transition-property: color;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a:hover {
  text-underline-offset: .25rem;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.hover\:border-stone-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: .8;
}

.hover\:text-stone-50:hover {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity));
}

.focus\:ring-offset-4:focus {
  --tw-ring-offset-width: 4px;
}

@media (min-width: 640px) {
  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (min-width: 768px) {
  .md\:rounded-full {
    border-radius: 9999px;
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:text-\[8vmin\] {
    font-size: 8vmin;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1024px) {
  .lg\:w-\[950px\] {
    width: 950px;
  }
}

/*# sourceMappingURL=contact.60aea44c.css.map */
